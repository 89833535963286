:root {
  --background-color: #fbf7ee;
  --primary-color: #611f69;
  --gradiant-color: #a535b3;
  --secondary-color: #fcb92b;
}

$primary: var(--primary-color);
$gradiant: var(--gradiant-color);
$backgroundColor: var(--background-color);
$secondaryColor: var(--secondary-color);

.fade-animation {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fade-animation-left {
  animation: fadeInLeft 1.1s;
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0px);
  }
}

.pluse-animation {
  animation: pluseAnimation 0.3s;
}

@keyframes pluseAnimation {
  0% {
    transform: scale(0.97); /* scaling to 0 */
  }
  50% {
    transform: scale(0.98); /* increasing the size */
  }
  70% {
    transform: scale(0.99); /* decreasing the size */
  }
  100% {
    transform: scale(1); /* seeting back to initial size */
  }
}

.text-primary {
  color: $primary !important;
}

.bg-primary {
  background: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.hover\:bg-primary:hover {
  background: $primary !important;
  color: white !important;
}

.complete_bot {
  position: relative;
  bottom: 3%;
  right: 0%;
  min-width: 380px;
  width: 100%;
  img {
    float: right;
  }
  .widget {
    margin-bottom: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 25px 0px;
    border-radius: 15px;
  }
}

.header {
  min-height: 160px;
  height: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #212226;
  font-weight: 400;
  position: relative;
  // background-color: $primary;
  // background-image: linear-gradient(315deg, $gradiant 0%, $primary 74%);
  .header_top {
    img {
      height: 14px;
      cursor: pointer;
    }
    .middle_top_header {
      img {
        height: 50px;
        width: 50px;
      }
      p {
        margin-left: 4px;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .header_bottom {
    h1 {
      font-size: 24px;
      margin-top: -8px;
      font-weight: 400;
    }
  }
  .sub_heading {
    font-size: 14px;
    font-weight: 400;
  }
  .top_card {
    background: linear-gradient(284.67deg, #579aff -1.51%, #0038ff 92.23%);
    border-radius: 15px;
    padding: 15px 15px 22px 15px;
    position: absolute;
    min-width: 89%;
    margin-top: 5px;
    .txt {
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 12px;
    }
    .info_text {
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      word-break: break-all;
    }
    .timerImg {
      opacity: 0.6;
    }
    .time {
      font-weight: 400;
      font-size: 13px;
      color: #ffffff;
      opacity: 0.6;
    }
    .send_btn {
      background-color: #fff;
      font-size: 13px;
      font-weight: 400;
      color: #212226;
      text-align: center;
      padding: 8px 10px;
      border-radius: 8px;
      border: 1px solid #fff;
      border: 1px solid #fff;
      &:hover {
        border-color: rgb(91, 40, 104, 0.1);
        border-width: 3px;
      }
    }
  }
}
// Message Body
.pd_Top {
  padding-top: 160px;
}
.message_inner_body {
  background-color: $backgroundColor;
  // height: 450px;
  // overflow-y: scroll;
  .meeting_card {
    min-width: 150px;
    .outer_container {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      padding: 15px 8px;
      display: flex;
      align-items: center;
      gap: 3px;
      width: 100%;
      height: 48px;
      color: #090a0a;
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      &:hover {
        color: #000;
        background: rgb(91, 40, 104, 0.1);
      }
      img {
        height: 20px;
      }
    }
  }
  .text {
    color: #090a0a;
    font-weight: 400;
    font-size: 13px;
  }
  .skipTxt {
    color: $primary;
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
    display: block;
    position: absolute;
    bottom: 20px;
    padding: 0px 20px;
    &:hover {
      color: $secondaryColor;
    }
  }
  .question_wrap {
    height: 254px;
    .size {
      font-size: 14px;
    }
    .questions {
      height: 225px !important;
      overflow-y: scroll;
      padding: 5px 15px 5px 1px;
      cursor: auto;
      .questionOuter {
        background: #f5f5f5;
        padding: 8px 10px;
        border: 0.3px solid #f1f1f1;
        box-shadow: rgba(91, 40, 104, 0.27) 0px 0px 0.15em, rgba(90, 125, 188, 0.05) 0px 0.15em 1em;
        border-radius: 12px;
        width: fit-content;
        margin-bottom: 8px;
        max-width: auto;
        color: #000;
        span {
          font-size: 13px;
          font-weight: 600;
          display: block;
          width: 100%;
          word-break: break-word;
        }
      }
      .message {
        background: #fff;
        padding: 5px 5px;
        border: 0.3px solid $primary;
        box-shadow: rgba(91, 40, 104, 0.27) 0px 0px 0.15em, rgba(90, 125, 188, 0.05) 0px 0.15em 1em;
        border-radius: 12px;
        width: fit-content;
        margin-bottom: 8px;
        max-width: auto;
        color: $primary;
        margin-left: 55px;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          background: $primary;
          border: 0.7px solid rgba(255, 255, 255, 0.4);
          box-shadow: 0px 4px 8px rgba(91, 40, 104, 0.4);
          color: #fff;
        }
        span {
          font-size: 13px;
          font-weight: 400;
          padding: 5px 10px;
          display: block;
          width: 100%;
          word-break: break-word;
        }
      }
      .message-answer {
        background: $primary;
        padding: 5px 5px;
        border: 0.7px solid rgba(255, 255, 255, 0.4);
        box-shadow: 0px 4px 8px rgba(91, 40, 104, 0.4);
        border-radius: 12px;
        width: fit-content;
        margin-bottom: 8px;
        max-width: auto;
        color: #fff;
        margin-left: 40px;
        span {
          font-size: 13px;
          font-weight: 400;
          padding: 0 10px 0;
          display: block;
          width: 100%;
          word-break: break-word;
        }
        small {
          font-size: 11px;
          margin-left: 9px;
        }
      }
    }
  }
}
.chat_body {
  overflow-x: hidden;
  .recieved {
    max-width: 220px;
    min-width: 80px;
    p {
      color: black;
      font-size: 12px;
    }
    small {
      color: #5a5555;
      float: right;
      font-size: 10px;
    }
  }

  .sent {
    max-width: 260px;
    min-width: 80px;
    p {
      color: white;
      font-size: 12px;
    }
    small {
      color: #dbd5d5;
      float: right;
      font-size: 10px;
      right: 10px;
    }
    .text-color {
      color: #5a5555 !important;
    }
  }
}
.emoji_picker::after {
  z-index: 0;
}
.emoji_picker {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
}
.message_body {
  /* Works on Chrome, Edge, and Safari */
  background: $backgroundColor;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    border-radius: 20px;
    border: none;
  }

  .calendar_body {
    .slider_container {
      margin-top: 2rem;
      // margin-bottom: -10px;
      .slick_btn_container {
        .cancel-btn {
          border: 1px solid $primary;
          font-size: 12px;
          width: 100px;
          height: 30px;
          border-radius: 5px;
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }
        }
        .confirm-btn {
          border: 1px solid $primary;
          background-color: $primary;
          font-size: 12px;
          color: white;
          width: 100px;
          height: 30px;
          border-radius: 5px;
          &:hover {
            background-color: $secondaryColor;
            color: $primary;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }
        }
      }
      .slick-prev {
        background-color: $primary !important;
        border-radius: 50% !important;
        padding: 5px;
        left: 0px !important;
      }
      .slick-next {
        background-color: $primary !important;
        border-radius: 50% !important;
        padding: 5px;
      }

      .date_slider {
        text-align: center;
        width: 250px;
        .date_option {
          border: 1px solid $primary;
          border-radius: 50%;
          height: 60px;
          width: 60px !important;
          .date_active {
            background-color: $primary;
            border: 1px solid transparent !important;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            color: white;
          }
          .dates {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
          }

          &:hover {
            background-color: $primary;
            color: white;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
            cursor: pointer;
          }
        }

        .time_option {
          border: 1px solid $primary;
          border-radius: 5px;
          height: 30px;
          width: 70px !important;
          cursor: pointer;
          .time_active {
            background-color: $primary;
            color: white;
          }
          &:hover {
            background-color: $primary;
            color: white;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
          }
          p {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    p {
      text-align: center;
      font-size: 12px;
      strong {
        font-size: 14px;
      }
    }
    span {
      color: rgb(12, 93, 126);
    }
  }
  .form_body {
    .form_container {
      p {
        font-size: 12px;
        margin-bottom: 1rem;
      }
      input {
        border: 1.5px solid black;
        width: 100%;
        border-radius: 5px;
        padding: 5px 10px;
        margin-bottom: 0.5rem;
        // height: 33px !important;
        &:focus {
          outline: #9ea8b7;
        }
        &::-webkit-input-placeholder {
          font-size: 12px;
        }
      }
      button {
        background: $primary;
        color: white;
        border-radius: 5px;
        width: 100%;
        font-size: 12px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        cursor: pointer;
        &:hover {
          background: $secondaryColor;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
        }
      }
    }
  }
  .welcome_message_container {
    margin-left: 14px;
    .time_current {
      font-size: 12px !important;
      right: 10px !important;
      bottom: 2px !important;
      color: #666668;
    }
    svg {
      height: 28px;
      width: 28px;
    }
    .welcome_message {
      margin-left: 5px;
      padding-top: 0.25rem !important;
      p {
        font-size: 13px;
        margin-top: 2px;
        word-break: normal;
      }
      span {
        position: absolute;
        font-size: 10px;
        color: #666668;
        right: 10px;
      }
    }
  }
  .chat_message_container {
    margin-right: 10px;
    img {
      height: 28px;
      width: 28px;
    }
  }
  .options_list {
    margin-left: 44px;
    .option {
      margin: 10px 10px 10px 5px;
      box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
      &:hover {
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
      }
      p {
        font-size: 12px;
      }
    }
  }
}
@keyframes openWidget {
  from {
    display: none;
  }
  to {
    display: unset;
  }
}
.normal_footer {
  height: 32px;
  background: $backgroundColor;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  p {
    font-size: 12px;
    color: $primary;
  }
  span {
    font-size: 12px;
    color: $primary;
    font-weight: bold;
  }
}
.footer {
  background: $backgroundColor;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  .input_container {
    height: 55px;
    .message_type_area::-webkit-scrollbar {
      display: none;
    }
    textarea:focus {
      outline: none !important;
    }
    .threedot {
      margin-left: 9px;
      cursor: pointer;
      height: 16px;
    }
    .cross {
      margin-left: 9px;
      cursor: pointer;
      height: 12px;
      width: 12px;
    }
    .customSelect {
      background: white;
      border-radius: 5px;
      color: black;
      font-size: 12px;
      padding-left: 6px !important;
      height: 33px;
      outline: 1.5px solid $primary;
      align-items: center;
    }
    .button_container {
      width: 100px;
      img {
        height: 14px;
        cursor: pointer;
      }
      button {
        width: 30px;
        height: 30px;
        background-color: $primary;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: $secondaryColor;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        }
      }
    }
    input {
      border: none;
      background: none;
      &:focus {
        outline: none;
      }
    }
  }
}
.footer_container {
  // height: 100px;
  position: absolute;
  bottom: -55px;
  left: 0px;
  right: 0px;
  // align-items: center;
  // justify-content: center;
  display: flex;
  flex-wrap: wrap;
  .footer_image_container {
    border-right: none;
    border-left: none;
    height: 60px;
    flex-direction: row;
    display: flex;
    align-items: center;
    background: $backgroundColor;
  }
  .brand_footer {
    text-align: center;
    padding-top: 10px;
    color: $primary;
    font-size: 12px;
    background: $backgroundColor;
    border-radius: 50%;
  }
}
.wrap3 {
  background: linear-gradient(124deg, $primary, $gradiant, $secondaryColor);

  background-size: 1800% 1800%;

  -webkit-animation: rainbow 10s ease infinite;
  -z-animation: rainbow 10s ease infinite;
  -o-animation: rainbow 10s ease infinite;
  animation: rainbow 10s ease infinite;
}

.wrap {
  background: linear-gradient(124deg, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);

  background-size: 1800% 1800%;

  -webkit-animation: rainbow 10s ease infinite;
  -z-animation: rainbow 10s ease infinite;
  -o-animation: rainbow 10s ease infinite;
  animation: rainbow 10s ease infinite;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

@-webkit-keyframes blink {
  0% {
    border-color: orange;
  }
  50% {
    border-color: pink;
  }
  100% {
    border-color: purple;
  }
}
@-moz-keyframes blink {
  0% {
    border-color: bisque;
  }
  50% {
    border-color: chocolate;
  }
  100% {
    border-color: firebrick;
  }
}
@-ms-keyframes blink {
  0% {
    border-color: #1de840;
  }
  50% {
    border-color: #dd00f3;
  }
  100% {
    border-color: skyblue;
  }
}
@keyframes blink {
  0% {
    border-color: red;
  }
  50% {
    border-color: lightcoral;
  }
  100% {
    border-color: rebeccapurple;
  }
}

.wrap2 {
  -webkit-animation: blink 1s infinite;
  -moz-animation: blink 1s infinite;
  -ms-animation: blink 1s infinite;
}

.wrap2 {
  border: 0.5px solid transparent;
}
@media screen and (max-height: 780px) {
  .message_body {
    height: 390px;
    width: 310px !important;
    border-top: 0 !important;
    overflow-y: scroll;
  }
  .header {
    width: 100% !important;
  }
  .welcome_message {
    width: 240px !important;
  }
  // .chat_body {
  //   height: 280px !important;
  // }
  .footer {
    // width: 310px !important;
    // height: 150px;
    // align-items: unset !important;
  }
  .branding {
    transform: translate(85%, 150%) !important;
  }
  .complete_bot {
    bottom: 10px !important;
  }
  .widget {
    // margin-bottom: 5px !important;
  }
  // .header {
  //   padding-top: 1rem !important;
  // }
  .blogs {
    padding-left: 1.5rem !important;
  }
}
@media screen and (max-width: 390px) and (min-height: 785px) {
  .complete_bot {
    right: 0 !important;
    bottom: 10% !important;
  }
  .widget {
    width: 340px;
  }
  .header {
    width: 340px;
  }
  .message_body {
    width: 340px;
    overflow-y: scroll;
  }
  .footer {
    width: 340px;
  }
}
@media screen and (max-height: 690px) {
  .message_body {
    height: 350px !important;
    overflow-y: scroll;
  }
  // .chat_body {
  //   height: 290px !important;
  // }
}
@media screen and (max-height: 600px) {
  .message_body {
    height: 280px !important;
    overflow-y: scroll;
  }
  // .chat_body {
  //   height: 220px !important;
  // }
}
@media screen and (max-height: 528px) {
  .message_body {
    height: 220px !important;
    overflow-y: scroll;
  }
  // .chat_body {
  //   height: 160px !important;
  // }
}
@media screen and (max-height: 450px) {
  .message_body {
    height: 140px !important;
    overflow-y: scroll;
  }
  // .chat_body {
  //   height: 80px !important;
  // }
}
@media screen and (max-width: 420px) {
  .widget {
    margin-right: 8px;
  }
}

.message_body .form_body .form_container {
  input {
    font-size: 12px;
    color: black;
    background: transparent;
    &::placeholder {
      color: black;
      font-size: 12px;
    }
  }
  select {
    background: transparent;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    color: black;
    font-size: 12px;
    padding-left: 6px !important;
    outline: 1.5px solid black;
    align-items: center;
    height: 30px;
  }
}
#root {
  right: 16px !important;
  bottom: 43px !important;
  position: fixed !important;
}
@keyframes wave {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}
.emoji_picker {
  left: 28px;

  position: absolute;
}
.d-flex {
  display: flex;
}
.file-attachment-container {
  border-width: 10px solid black;
  width: 50px;
  height: 45px;
  margin: 2px;
  margin-left: 12px;
  .file-attachment-image {
    width: 100%;
    height: 100%;
  }
  .attach-close-image {
    background-color: $primary !important;
    position: absolute;
    top: 5px;
    // left: 55px;
    z-index: 1;
    height: 17px;
    width: 17px;
    transition: 0.5s ease;
    opacity: 0;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
.file-attachment-image:hover {
  -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
  filter: grayscale(50%);
}
.file-attachment-container:hover {
  .attach-close-image {
    opacity: 1;
  }
}

.chat-input {
  width: 70%;
  font-family: auto;
  cursor: text;
  width: 100%;
  font-family: auto;
  background: transparent;
  outline: 0;
  left: 0px;
  color: rgb(0, 0, 0);
  resize: none;
  border: none;
  transition: background-color 200ms ease 0s, box-shadow 200ms ease 0s;
  outline-offset: -5px;
  box-sizing: border-box;
  padding: 18px 130px 18px 16px;
  width: 100%;
  height: 100%;
  font-family: intercom-font, 'Helvetica Neue', 'Apple Color Emoji', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-height: 55px;
  overflow: auto;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: $primary;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #dddddd;
  }
  28% {
    transform: translateY(-7px);
    background-color: #dddddd;
  }
  44% {
    transform: translateY(0px);
    background-color: #dddddd;
  }
}
.audio-close {
  position: absolute;
  z-index: 99999999;
  margin-left: 5px;
  top: 0px;
}
.p-0 {
  padding: 0px !important;
}
.custom-form {
  .margin-bottom-3 {
    margin-bottom: 0.2rem;
  }
  .mb-3 {
    margin-bottom: 0;
  }
}
.slick_btn_container {
  .cancel-btn {
    margin-right: 10px;
    background: white !important;
    border: 1px solid $primary !important;
    color: $primary !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    }
  }
}
.w-100 {
  width: 100% !important;
}

.chat-loader {
  width: 25px;
  position: absolute;
  left: 45%;
  top: 35%;
}
.main-screen-loader {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash-body {
  width: 380px;
  height: 610px;
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  .splash-logo {
    margin: 0 auto;
    padding-top: 120px;
  }
  .splash-robot {
    position: absolute;
    bottom: 0;
    width: 98%;
    border-radius: 0 0 12px 12px;
  }
}

.splash-screen {
  position: relative;
  bottom: 10px;
  right: 0%;
  svg {
    top: 40%;
    margin: 0 auto;
  }
  .widget {
    margin-bottom: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 25px 0px;
    border-radius: 15px;
  }
}

.splash_footer {
  height: 30px;
  background: #eeebe4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  p {
    font-size: 12px;
    color: #611f69;
  }
  span {
    font-size: 12px;
    color: #611f69;
    font-weight: bold;
  }
}

.splash-border-primary {
  border-color: #611f69 !important;
}
.question-box-height {
  max-height: 168px;
}

.error_container {
  position: absolute;
  bottom: 5px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-wrap: wrap;
}
.error-close {
  border-radius: 50%;
  cursor: pointer;
  width: 4%;
  height: 4%;
  margin-right: 5px;
}
.custom-modal {
  z-index: 1111111;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.custom-modal-content {
  background-color: white;
  padding: 8px;
  font-size: 12px;
  border: 1px solid $primary;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.custom-modal-btn {
  border: 1px solid $primary;
  font-size: 12px;
  width: 50px;
  height: 30px;
  float: right;
  border-radius: 5px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.audio-attachment-container {
  margin-left: 10px;
  .audio-attachment-image {
    width: 100%;
    height: 100%;
  }
  .attach-close-image {
    background-color: $primary !important;
    position: absolute;
    top: 5px;
    // left: 55px;
    z-index: 1;
    height: 17px;
    width: 17px;
    transition: 0.5s ease;
    opacity: 0;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
.audio-attachment-image:hover {
  -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
  filter: grayscale(50%);
}
.audio-attachment-container:hover {
  .attach-close-image {
    opacity: 1;
    margin-left: 10px;
    top: 2px;
  }
}
.emoji-close {
  background: $primary;
  padding: 4px;
  border-radius: 2px;
}

.questions-chat {
  color: white;
  font-size: 13px;
  margin-top: 2px;
  word-break: normal;
}

.questionsWindow {
  overflow-y: auto;
  height: 350px;
}
.dropdown {
  position: relative;
  .dropdown-menu {
    position: absolute;
    right: 0;
    min-width: 180px;
    background-color: white;
    color: black;
    border-radius: 5px;
    ul li {
      padding: 7px 10px;
    }
  }
}
.send-transcript-success {
  text-align: center;
}
.send-transcript-body {
  padding: 20px;
}
.send-transcript-success-checkmark {
  vertical-align: middle;
  font-weight: 700;
}
.send-transcript-success-text {
  font-size: 12px;
}
.send-transcript-success-back-button {
  width: 75%;
  height: 32px;
  margin-top: 10px;
  background: $primary;
  color: white;
  border: 1px solid #898989;
  border-radius: 5px;
  cursor: pointer;
}
// Small Header
.headerSmall {
  height: 65px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: $primary;
  font-weight: 400;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  .title {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }
  .sub-title {
    font-size: 14px;
    font-weight: 400;
  }
}
// Form
.form {
  height: 530px;
  overflow-y: auto;
  label {
    font-size: 15px;
    font-weight: 400;
  }
  p {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px 15px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-bottom: 5px;
    background: white;
    padding: 15px;
  }
  .formInput {
    background: #fff;
    border: 1px solid rgba(91, 40, 104, 0.3);
    height: 40px;
    outline: 0;
    width: 100%;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    &.pr-0 {
      padding-right: 0;
      width: 85px;
    }
    &.pl-0 {
      padding-left: 10px;
    }
  }
  .formInput::placeholder {
    color: #5b2868;
    opacity: 0.5;
  }
  .submitBtn {
    height: 40px;
    background: $primary;
    border-radius: 12px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    &:hover {
      background: $secondaryColor;
    }
  }
}
.text {
  font-size: 14px;
  font-weight: 400;
}

// Blogs
.blogs_body_outer_initial {
  height: 424px;
  overflow-y: hidden;
  position: relative;
}

.blogs_screen {
  width: 100%;
  background-color: $backgroundColor;
  .searchOuter {
    margin-bottom: 3px;
    position: relative;
    padding: 7px 30px 7px 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 2px -3px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 8px;
    font-size: 13px;
    margin: 15px 20px 7px;
    .searchInput {
      width: 100%;
      outline: 0;
      background-color: $backgroundColor;
    }
    .searchIcon {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }

  .blogs_body_outer {
    height: 424px;
    overflow-y: scroll;
    position: relative;
    .blogs_body {
      margin: 0 0 8px 0;
      cursor: pointer;
      .blogs_img {
        border: 8px solid #ffffff;
        border-radius: 20px;
        height: 140px;
        width: 100%;
        margin-bottom: 5px;
      }

      .blogs_text {
        color: #353535;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0028em;
        word-break: break-word;
        border-bottom: 1px solid #ddd;
        margin: 0 10px;
        padding: 10px 0;
      }
    }
  }
}
.blogs_body_outer-2 {
  // height: 480px;
  // position: relative;
}
.blogs_outer-div {
  height: 480px;
  overflow-y: auto;
}
.blogs_outer-div-2 {
  height: 424px;
  overflow-y: auto;
}
.blogs_body:hover .blogs_img {
  transform: scale(1.03);
  transition: 0.5s all ease-in-out;
}
.blogs_header {
  background-color: $primary;
  color: #fff;
  height: 65px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  .top {
    display: flex;
    gap: 10px;
    align-items: center;
    h3 {
      font-size: 20px;
    }
  }
}
.widget_layout {
  height: 610px;
  background-color: $backgroundColor;
  width: 380px;
}
.chatbot_header {
  min-height: 65px;
  height: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: $primary;
  font-weight: 400;
  position: relative;
  padding-top: 17px;
  padding-bottom: 10px;
  width: 100%;
  .name {
    font-weight: 700;
    font-size: 14px;
  }
  .activeBullet {
    width: 8px;
    height: 8px;
    background: #7dde86;
    border-radius: 100%;
    margin-left: 5px;
  }
  .activeMessage {
    color: #e2e2e2;
    font-size: 12px;
    font-weight: 300;
  }
}

.new-chat-bot {
  height: 486px;
  overflow-y: auto;
  .new-chat-body {
    // display: flex;
    flex-direction: column;
    justify-content: end;
  }
}

.chat_bot {
  .chatBody {
    height: 486px;
    overflow-y: auto;
    .date {
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      display: block;
    }
    .receiveMessageOuter {
      display: flex;
      gap: 10px;
      .botIcon {
        align-self: flex-start;
        height: 32px;
        width: 32px;
        object-fit: contain;
      }
      .logo {
        min-width: 32px;
        width: 32px;
      }
      .receiveMessage {
        background: #f2f4f5;
        border-radius: 0px 24px 24px 24px;
        padding: 10px 15px;
        margin-bottom: 15px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #303437;
          margin: 0;
          word-break: break-word;
        }
        .date {
          margin-bottom: 0 !important;
          margin-right: 0 !important;
          color: #afa7a7;
        }
      }
    }
    .sendMessage {
      background: $primary;
      border-radius: 24px;
      width: auto;
      max-width: 75%;
      border-radius: 24px 0 24px 24px;
      margin-bottom: 15px;
      padding: 10px 15px;
      color: #fff;
      p {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        word-break: break-word;
      }
      .date {
        margin-bottom: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
  .icon {
    height: 18px;
    cursor: pointer;
  }
  .sendIcon {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    cursor: pointer;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-left: auto;
    img {
      height: 18px;
    }
    &:hover {
      background: $secondaryColor;
    }
  }
  .chatFooterOuter {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    height: 60px;
    .chatbot_footer {
      display: flex;
      justify-content: end;
      gap: 13px;
      position: absolute;
      right: 10px;
      width: -webkit-fit-content;
    }
  }
}
.shadow {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.bottomFooter {
  bottom: 8px;
  right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: $primary;
  box-shadow: 10px 14px 56px rgba(0, 0, 0, 0.12);
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: absolute;
  .nav_link {
    cursor: pointer;
    padding: 5px 10px;
    min-width: 90px;
    &:hover,
    &:focus {
      color: #fff;
      background: rgba(255, 255, 255, 0.1);
      padding: 5px 10px;
      border-radius: 48px;
      span {
        display: block;
      }
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      display: none;
    }
  }
  .navActive {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 48px;
    span {
      display: block;
    }
  }
}
.blog_detail {
  .detail_body {
    height: 480px;
    overflow-y: scroll;
    padding-top: 20px;
    padding-bottom: 15px;
    .img {
      height: 150px;
      width: 100%;
      border-radius: 12px;
    }
    .detail_body_title {
      font-weight: 600;
      font-size: 22px;
      letter-spacing: 0.5px;
      line-height: normal;
    }
    .detail_subtitle {
      color: #777;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .blogs_date {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 10px;
      display: block;
    }
    .detail_body_heading {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 5px;
    }
    .blogs_text {
      div {
        p {
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
          margin-bottom: 12px;
        }
      }
    }
    .blog_list {
      list-style-type: square;
      li {
        font-size: 14px;
        margin-top: 8px;
        margin-left: 12px;
      }
    }
  }
}
.robotImg {
  position: absolute;
  right: 36px;
  top: 15px;
  height: 165px;
}
.outer_layer {
  -webkit-mask-image: url('../images/layer1.svg');
  mask-image: url('../images/layer1.svg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 223px;
  background: $gradiant;
  position: relative;
  .inner_layer {
    background: $secondaryColor;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-mask-image: url('../images/layer2.svg');
    mask-image: url('../images/layer2.svg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 216px;
  }
  .main_layer {
    background: linear-gradient(152.5deg, $primary 33.08%, $gradiant 82.88%);
    -webkit-mask-image: url('../images/layer3.svg');
    mask-image: url('../images/layer3.svg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 206px;
    .main_box {
      width: 200px;
      position: relative;
      z-index: 9;
      word-wrap: wrap;
      word-break: break-word;
    }
    .greeting_text {
      font-size: 26px;
      font-weight: 700;
    }
    .meta_text {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 14px;
    }
    .send_btn {
      background-color: #fff;
      font-size: 13px;
      font-weight: 400;
      color: #212226;
      text-align: center;
      padding: 8px 10px;
      border-radius: 12px;
      border: 1px solid #fff;
      border: 1px solid #fff;
      &:hover {
        border-color: rgb(91, 40, 104, 0.1);
        border-width: 3px;
      }
    }
  }
}
.svgIcon path {
  // fill: #5b2868;
}
.svgWhite path {
  fill: #fff;
  stroke: none;
}
.bakcArrow {
  height: 14px;
  margin-top: 5px;
}
.chat-image {
  max-height: 150px;
  width: 100%;
  border-radius: 12px;
  background-size: contain;
}
.dotsImg {
  position: absolute;
  right: 20px;
  top: 23px;
  z-index: 9;
  cursor: pointer;
}
.dropdown-menu {
  background-color: #fff;
  padding: 10px 10px;
  font-size: 13px;
  border-radius: 4px;
  color: #000;
  margin: 20px 10px 0 0;
}

.slider-main {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  cursor: pointer;
  height: 80px;
  .box-outer {
    max-width: 50px;
    left: 24px;
    margin-left: 5px;
    background: #ffffff;
    // box-shadow: 0px 21.8201px 21.8201px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    transform: scale(1);
    transition: 0.1s ease-in all;
    &.selected {
      transform: scale(1.2);
      min-width: 55px !important;
      margin: 0 auto !important;
      .group-box {
        border-top: 15px solid $primary;
        min-width: 55px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .box {
          .date {
            font-size: 20px;
          }
          .day {
            font-size: 20px;
          }
        }
      }
    }

    .group-box {
      width: 50px;
      height: 68px;
      top: 150px;
      border-top: 15px solid $gradiant;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transition: 0.1s ease-in all;
      .box {
        .date {
          height: 27px;
          left: 35px;
          top: 171.17px;
          color: #000000;
        }
        .day {
          height: 19.64px;
          left: 37px;
          top: 192.29px;
          color: #000000;
        }

        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}

.modal-wrapper {
  max-width: 300px;
  background-color: $primary;
  padding: 5px 10px 5px 15px;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  animation: fall-down 0.3s forwards;
  z-index: 9999999999;
  .modal-content {
    h3 {
      font-size: 15px;
      color: #ffdf6f;
    }
    .modal-meeting-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;
      input {
        background: transparent;
        outline: none;
        border: none;
        font-size: 20px;
        color: #ffffff;
        accent-color: #ffffff;
        max-width: 200px;
        &::placeholder {
          color: #ffffff;
        }
      }
      button {
        background-color: #ffffff;
        color: $primary;
        padding: 5px;
        border: $primary;
        font-size: 13px;
        border-radius: 5px;
        transition: 0.1s ease-in all;
        &:hover {
          background-color: $backgroundColor;
          color: $gradiant;
        }
      }
    }
    .time-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;
      margin-top: 10px;
      .time-slots {
        border: 1px solid #ffffff;
        padding: 5px 10px;
        color: #ffffff;
        transition: 0.1s ease-in all;
        border-radius: 5px;
        font-size: 13px;
        &:hover {
          background-color: $backgroundColor;
          color: $primary;
        }
      }
    }
  }
  .modal-cross {
    position: absolute;
    width: 13px;
    height: 13px;
    right: 10px;
    top: 10px;
  }
}

@keyframes fall-down {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.time-list {
  padding: 0px 15px 0px 15px;
  border-radius: 0px;
  max-height: 280px;
  overflow-y: auto;
  // padding: 15px;
  // border-radius: 0px;
  // max-height: 335px; /* set the desired height */
  // overflow-y: auto; /* add a scroll if content exceeds height */
  .time-group {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    // min-height: 50px;
    .time {
      display: flex;
      justify-content: center;
      /* margin-right: 10px; */
      width: 45px;
      /* height: 19.64px; */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      min-height: 50px;
      /* line-height: 18px; */
      /* color: #000000; */
      /* cursor: pointer;*/
    }
    .time-line {
      display: inline-block;
      width: 96%;
      margin-top: 5px;
      border-top: 1px solid #cfcae4;
      cursor: pointer;
      padding-top: 50px;
    }
    .time-line-second {
      display: inline-block;
      width: 96%;
      margin-top: 5px;
      border-top: 1px solid #cfcae4;
      cursor: pointer;
    }
  }
}

.calendar-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  column-gap: 15px;
  .cancel-btn-calendar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid $primary;
    border-radius: 8px;
    width: 150px;
    height: 50px;
  }
  .confirm-btn-calendar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    border-radius: 8px;
    color: white;
    background: $primary;
    border-radius: 8px;
    width: 150px;
    height: 50px;
  }
}

// Slick Slider Settings
.slick-list {
  min-height: 120px !important;
  max-width: 330px !important;
  margin: 0 auto !important;
}
.next_Arrow {
  background-color: #0038ff;
}

.disabled {
  color: #a0a0a0 !important;
  pointer-events: none !important;
}

.disabled-input{
  cursor: not-allowed;
}

.disable-confirm-btn-calendar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  border-radius: 8px;
  opacity: 0.9;
  color: white;
  background: #a0a0a0;
  pointer-events: none !important;
  border-radius: 8px;
  width: 150px;
  height: 50px;
}

.messages-body {
  overflow-y: auto;
  // height: 485px;
  .lists {
    .list {
      .message-box {
        position: relative;
        // display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 16px 20px;
        -webkit-box-align: start;
        align-items: start;
        transition: color 250ms ease 0s, background-color 250ms ease 0s;
        opacity: 1;
        cursor: pointer;

        .image {
          margin-right: 8px;
          align-self: center;
        }
        .text {
          margin-right: auto;
          flex: 1 1 0%;
          font-size: 14px;
          line-height: 21px;
          min-width: 0px;
          .column {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            .text-wrap {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 400;
              width: 55%;
              .white-space {
                white-space: nowrap;
                font-size: 14px;
                font-weight: 400;
              }
            }
            .admin {
              font-weight: 400;
              font-size: 14px;
              color: rgb(102, 102, 102);
              display: flex;
              white-space: break-spaces;
              .admin-text {
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 4px;
                white-space: nowrap;
              }
              .date {
                white-space: nowrap;
              }
            }
          }
        }
        ::after {
          content: '';
          position: absolute;
          height: 1px;
          bottom: 0px;
          left: 20px;
          right: 20px;
          background-color: rgba(0, 0, 0, 0.01);
        }
      }
    }
  }
}

.main_body {
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  flex: 1 1 0%;
  height: 326px !important;
}

.recent_message_wrap {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  padding: 16px 20px;
  background: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  &.blogs-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    font-size: 14px;
    line-height: 21px;
  }
  .searchOuter {
    position: relative;
    padding: 7px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 2px -3px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 8px;
    font-size: 13px;
    .searchInput {
      &:focus-visible {
        outline: none;
      }
    }
  }
}

.send-special-message {
  background: transparent;
  border-radius: 24px;
  width: auto;
  max-width: 70%;
  border-radius: 6px;
  margin-bottom: 15px;
  border: 1px solid $primary;
  white-space: break-spaces;
  height: auto;
  color: $primary;
  text-align: start;
  padding: 7px 10px;
  cursor: pointer;
  &:hover {
    background: $primary;
    color: #ffffff;
  }
  p {
    word-break: break-all;
  }
}

.send-special-message-selected {
  background: $primary;
  border-radius: 24px;
  width: auto;
  max-width: 70%;
  border-radius: 6px;
  margin-bottom: 15px;
  border: 1px solid $primary;
  white-space: break-spaces;
  height: auto;
  color: #ffffff;
  text-align: start;
  padding: 7px 10px;
  p {
    word-break: break-all;
  }
}

.greeting-message {
  color: rgb(115, 115, 118);
}

.unReadMessage {
  background: red;
  border-radius: 50%;
  color: white;
  height: 20px;
  width: 20px;
  text-align: center;
  margin-left: 10px;
}
.contact-flex {
  display: flex;
  align-items: center;
  justify-content: center; /* Added */
  margin-bottom: 14px;
  background: #fff;
  border: 1px solid rgba(91, 40, 104, 0.3);
  // border-radius: 12px;
  // height: 48px;
  outline: 0;
  width: 100%;
  // padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
}
.blog-set {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh; /* Assuming you want to center the content vertically on the viewport */
  overflow-y: hidden;
}
.disabledButton {
  opacity: 0.5;
  pointer-events: none;
}

// Responsive Fixes
@media screen and (max-width: 419px) {
  .complete_bot {
    min-width: unset !important;
    width: 100% !important;
    max-width: 300px !important;
    max-height: 465px !important; //height changed
  }
  .widget {
    max-height: 465px !important; //height changed
  }
  .widget_layout {
    width: 300px;
    height: 457px;
  }
  .message_inner_body {
    max-height: 500px;
  }
  .main_body {
    max-height: 240px;
    overflow-y: scroll;
  }
  .card_flex {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
  }
  .chat-input {
    padding: 18px 130px 18px 8px;
    font-size: 13px;
  }
  .chatbot_footer {
    gap: 9px !important;
  }
  .box-outer {
    max-width: 40px !important;
  }
  .group-box {
    width: 40px !important;
  }
  .slick-list {
    max-width: 250px !important;
  }
  .slider-main .box-outer.selected {
    min-width: 40px !important;
  }
  .slider-main .box-outer.selected .group-box {
    min-width: 40px !important;
  }
  .disable-confirm-btn-calendar,
  .calendar-footer .cancel-btn-calendar {
    width: 100px !important;
    height: 100% !important;
    max-height: 30px !important;
    border-radius: 4px !important;
  }
  .splash-body {
    width: 280px !important;
  }
  .bottomFooter {
    width: 90% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 35px;
    width: unset !important;
  }
  // masking
  .outer_layer {
    -webkit-mask-size: 315px;
    height: 175px;
  }
  .inner_layer {
    -webkit-mask-size: 310px;
  }
  .main_layer {
    -webkit-mask-size: 300px;
    height: 170px !important;
  }
  .main_box {
    margin-bottom: 30px !important;
  }
  .meta_text {
    margin-bottom: 8px;
  }
  .send_btn {
    padding: 5px 5px !important;
    border-radius: 5px !important;
  }
  .robotImg {
    position: absolute;
    right: 36px;
    top: 15px;
    height: 120px;
  }
  //Masking ends
  .outer_container {
    height: 30px !important;
    padding: 8px !important;
    border-radius: 5px !important;
  }
  // Form
  .form {
    padding-top: 10px;
  }
  .formInput {
    height: 40px !important;
    padding: 0px 10px !important;
  }
  // Chat screen
  .chatBody {
    height: 335px !important;
  }
  // Meeting Screen
  .time-list {
    max-height: 160px !important;
  }
  .confirm-btn-calendar {
    height: 30px !important;
    width: 100px !important;
    border-radius: 4px !important;
  }
  .schedule_header_text {
    margin: 0px 0px 0px 15px;
  }
  // Blogs
  .blogs_outer-div-2 {
    height: 285px !important;
  }
}
.reset {
  font-size: 12px;
  background-color: $primary;
  padding: 5px;
  color: white;
  border-radius: 5px;
  &:hover {
    background: $secondaryColor;
  }
}

.progress {
  background-color: $primary;
}

.input-bg {
  // position: relative;
  // box-sizing: border-box;
  // overflow: hidden;
  // padding: 5px 10px;
  // background: white;
  // border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  position: relative;
  padding: 26px 24px 24px;
  // margin-left: 45px;
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, $primary 0px 2px 0px 0px;
}

.submitBtnSmall{
  cursor: pointer;
  z-index: 1;
  position: inherit;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: $primary;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}
.justify-content-between {
  justify-content: space-between;
}
